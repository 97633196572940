import customSelectStyles from '../../sass/includes/custom_float.scss';

(function( $ ) {

    $.fn.customFloat = function() {

        var jqAllAffectedContainers = this;

        var bounceOnce = function(jqContainer) {
            if (jqContainer.hasClass('float-up')) {
                jqContainer.removeClass('float-up');
            }
            if (!jqContainer.hasClass('float-down')) {
                jqContainer.addClass('float-down');
            }
            setTimeout(function() {
                jqAllAffectedContainers.each(function() {
                    var jqContainer = $(this);
                    if (jqContainer.hasClass('float-down')) {
                       jqContainer.removeClass('float-down');
                    }
                    if (!jqContainer.hasClass('float-up')) {
                       jqContainer.addClass('float-up');
                    }
               });
            }, 800);
        }

        return jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            bounceOnce(jqContainer);
            let timerId = setInterval(bounceOnce, 1600, jqContainer);
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.custom-float').customFloat();
});
