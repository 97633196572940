$('.gallery-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {

            }
        },
        {
            breakpoint: 767,
            settings: {

            }
        },
    ]
});
