(function( $ ) {

    $.fn.timetableXSSlider = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var sliderContainer = jqContainer.find('.timetable-xs-slider');
            if (!sliderContainer || sliderContainer === undefined) {
                console.error('timetable-xs-slider: no timetable-xs-slider fround in', this);
            }
            var prev = jqContainer.find('.prev');
            var next = jqContainer.find('.next');;
            sliderContainer.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                infinite: false,
                prevArrow: prev,
                nextArrow: next,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {

                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {

                        }
                    },
                ]
            });
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {

    $('.timetable-xs-slider-block').timetableXSSlider();

});
