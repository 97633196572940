import indexStyles from '../sass/index.scss';
import customFloatJS from './includes/custom_float.js';
import customTabsJS from  './includes/custom_tabs.js';
import gallerySliderJS from  './includes/gallery_slider.js';
import reviewsSliderJS from  './includes/reviews_slider.js';
import pricesSliderJS from  './includes/prices_slider.js';
import timetableSliderJS from  './includes/timetable_xs_slider.js';
import logoIMG from '../img/logo.svg';
import mainBlockImageIMG from '../img/main-block-image.png';
import mainBkgIMG from '../img/main-bkg.svg';
import sloganImageIMG from '../img/slogan-image.png';
import pricesImagesIMG from '../img/prices-images.png';
import pricesBkgIMG from '../img/prices-bkg.png';
import timetableBkgIMG from '../img/timetable_bkg.png';
import customIMG from '../img/custom-img.jpg';
import specialOffera1BkgIMG from '../img/special-offers-1-bkg.png';
import specialOffera2BkgIMG from '../img/special-offers-2-bkg.png';
import video1IMG from '../img/video-1.png';
import video2IMG from '../img/video-2.png';
import video3IMG from '../img/video-3.png';
import video4IMG from '../img/video-4.png';
import video5IMG from '../img/video-5.png';
import video6IMG from '../img/video-6.png';
import gallery1IMG from '../img/gallery-1.png';
import gallery2IMG from '../img/gallery-2.png';
import gallery3IMG from '../img/gallery-3.png';
import gallery4IMG from '../img/gallery-4.png';
import gallery5IMG from '../img/gallery-5.png';
import gallery6IMG from '../img/gallery-6.png';
import youtubeBtnIMG from '../img/youtube_btn.png';
import videoGalleryBkgIMG from '../img/video-gallery-bkg.png';
import author1IMG from '../img/author-1.png';
import author2IMG from '../img/author-2.png';
import reviewBkgIMG from '../img/review-bkg.png';
import reviewIMG from '../img/review-image.png';
import mapIMG from '../img/map.png';
