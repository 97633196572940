(function( $ ) {

    $.fn.reviewsSlider = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var sliderContainer = jqContainer.find('.reviews');
            if (!sliderContainer || sliderContainer === undefined) {
                console.error('reviews-slider: no reviews fround in', this);
            }
            var prev = jqContainer.find('.prev');
            var next = jqContainer.find('.next');
            var nav = jqContainer.find('.nav');
            sliderContainer.slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                infinite: false,
                prevArrow: prev,
                nextArrow: next,
                appendDots: nav,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 1,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            dots: false
                        }
                    },
                ]
            });
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {

    $('.reviews-slider').reviewsSlider();

});
